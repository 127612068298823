<template>
  <zb-admin-component/>
</template>
<script>
import ZbAdminComponent from '../modules/zb-admin'
export default {
  components: {
    ZbAdminComponent
  }
}
</script>
