import { mapActions } from 'vuex'
import { ServiceFactory } from '@/services/ServiceFactory'
const _authService = ServiceFactory.get('AuthService')
export default {
  name: 'ZbHeader',
  data: () => ({
    menu: false,
    searchText: '',
    api: {
      part: 'snippet',
      type: 'video',
      order: 'viewCount',
      maxResults: 12,
      q: '',
      prevPageToken: '',
      nextPageToken: ''
    }
  }),
  computed: {
    currentRouteName () {
      return this.$route.name
    },
    iconTitle () {
      return this.$route.meta.icon
    }
  },
  methods: {
    ...mapActions('authModule', ['removeCredentials']),
    logOutSubmit () {
      _authService.logOutSubmit()
        .then((resp) => {
          this.removeCredentials()
          return Promise.resolve()
        })
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch((err) => {
          this.$notify({
            type: 'error',
            text: err.msg
          })
        })
    }
  }
}
