<template>
<v-app>
  <!-- <lt-preloader :showLoader="true"></lt-preloader> -->
  <div class="bm-container bm-container-global full">
    <div class="full bm-container bm-item h-full zb-layout cross-start">
      <zb-sidebar />
      <section class="bm-container bm-section bm-item cross-start full" :class="miniSidebar  ? 'mini__sidebar' : ''">
        <zb-header/>
          <main class="bm-item bm-main scrollbar">
            <slot></slot>
          </main>
      </section>
    </div>
  </div>
</v-app>
</template>

<script>
import { mapState } from 'vuex'
// import LtPreloader from './lt-preloader'
import ZbHeader from './zb-header'
import ZbSidebar from './zb-sidebar'
export default {
  name: 'LtAdminLayout',
  components: {
    // LtPreloader,
    ZbHeader,
    ZbSidebar
  },
  computed: {
    ...mapState('uiModule', ['miniSidebar'])
  }
}
</script>

<style lang="scss">
  @import 'src/sass/mixins.scss';
  .bm-container.bm-container-global {
    position: relative;
    min-height: 100vh;
  }
  .bm-item.bm-container.bm-section {
    width: calc(100% - 300px);
  }
  @include to (l) {
    .bm-item.bm-container.bm-section {
      padding-left: 80px;
      width: 100%;
    }
  }
  .bm-container.zb-layout {
    margin-left: auto;
    margin-right: auto;
  }
  .bm-main {
    position: relative;
    max-height: calc(100vh - 6rem);
    overflow: auto;
  }
  .bm-item.bm-container.bm-section.mini__sidebar {
    padding-left: 80px;
    width: 100%;
  }
</style>
