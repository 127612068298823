import { mapMutations, mapGetters, mapState } from 'vuex'

export default {
  name: 'LtSideBar',
  data () {
    return {
      dialog: false,
      id: null,
      count: 100,
      right: null,
      show: true,
      windowSize: {
        x: 0,
        y: 0
      }
    }
  },
  mounted () {
    const mediumBp = matchMedia('(min-width: 1024px)')
    const changeSize = mql => {
      mql.matches
        ? this.$store.state.uiModule.miniSidebar = false
        : this.$store.state.uiModule.miniSidebar = true
    }
    mediumBp.addListener(changeSize)
    changeSize(mediumBp)
  },
  computed: {
    ...mapState('uiModule', ['miniSidebar']),
    ...mapGetters({
      authCredentials$: 'authModule/authCredentials'
    })
  },
  methods: {
    ...mapMutations('uiModule', ['MINI_SIDEBAR']),
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    }
  }
}
